const registerGoogleMap = () => {
	// Declare map
	const googleMap = document.querySelectorAll('#map');

	// Google Maps
	googleMap.forEach((element) => {
		function createMap(el, lat, long, zoom, marker) {
			// Set a center point of the map, can be re-evaluated using `centerMap()`
			const center = { lat: lat, lng: long };

			const mapOptions = {
				zoom: zoom,
				center: center,
				fullscreenControl: false,
			};

			// Create a map instance.
			const melonMap = new MelonMap(document.getElementById(el), mapOptions);

			// Marker locations [name, lat, long, zindex]
			const markerLocation = [['Buckinghamshire Building Society', lat, long, 1]];

			if (marker == true) {
				markerLocation.forEach((markerLocation) => {
					const title = markerLocation[0];

					const location = {
						lat: parseFloat(markerLocation[1]),
						lng: parseFloat(markerLocation[2]),
					}

					melonMap.addMarker(location, title);
				});
			}

			// Add a map style
			melonMap.setMapStyle(
				[{ "featureType": "landscape", "stylers": [{ "saturation": -100 }, { "lightness": 60 }] }, { "featureType": "road.local", "stylers": [{ "saturation": -100 }, { "lightness": 40 }, { "visibility": "on" }] }, { "featureType": "transit", "stylers": [{ "saturation": -100 }, { "visibility": "simplified" }] }, { "featureType": "administrative.province", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "on" }, { "lightness": 30 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ef8c25" }, { "lightness": 40 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi.park", "elementType": "geometry.fill", "stylers": [{ "color": "#b6c54c" }, { "lightness": 40 }, { "saturation": -40 }] }, {}],
				{ name: 'Styled Map' }
			);
		}

		// Initiate google map for contact form
		createMap('map', 51.51489892332676, -0.12353567116369213, 16, true);
	});
}

const registerAOS = () => {
	AOS.init({
		duration: 500,
		offset: 5,
		startEvent: 'load',
	});

	AOS.refresh();
}


document.addEventListener('DOMContentLoaded', () => {
	registerGoogleMap();
	registerAOS();

	const body = document.querySelector('body');

	// Helper function
	let domReady = (cb) => {
		document.readyState === 'interactive' || document.readyState === 'complete'
			? cb()
			: document.addEventListener('DOMContentLoaded', cb);
	};

	domReady(() => {
		// Display body when DOM is loaded
		body.style.visibility = 'visible';
	});


	// Google Recaptcha v3
	grecaptcha.ready(function () {
		document.querySelectorAll('form.mod-recaptcha').forEach((element) => {
			grecaptcha.execute('6LfPqD4qAAAAABhM0uM1n9HoihE6sKaPrHHjEBcV', { action: 'submit' }).then(function (token) {
				$('<input>').attr({
					type: 'hidden',
					name: 'g-recaptcha-response',
					value: token
				}).appendTo(element)
			});
		});
	});

	// Identify the device as touch device
	function hasTouch() {
		return 'ontouchstart' in document.documentElement
			|| navigator.maxTouchPoints > 0
			|| navigator.msMaxTouchPoints > 0;
	}

	// Hack to remove all hover effects on touch devices
	if (hasTouch()) { // remove all :hover stylesheets
		try { // prevent exception on browsers not supporting DOM styleSheets properly
			for (var si in document.styleSheets) {
				var styleSheet = document.styleSheets[si];
				if (!styleSheet.rules) continue;

				for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
					if (!styleSheet.rules[ri].selectorText) continue;

					if (styleSheet.rules[ri].selectorText.match('btn:hover')) {
						styleSheet.deleteRule(ri);
					}
				}
			}
		} catch (ex) { }
	}

	// Main nav fixed scroll

	if (!hasTouch()) {
		$(window).on('load scroll', function () {
			const scroll = $(window).scrollTop(),
				header = $('.header-main');

			if (scroll > 15) {
				header.addClass('mod-up');
			} else {
				header.removeClass('mod-up');
			}

		});
	}

	// hide cookie policy
	document.querySelector('.section-cookies .btn-accept').addEventListener('click', (e) => {
		e.preventDefault();

		// If no validation errors
		$.ajax({
			method: "POST",
			url: "api/cookies/",
			data: {
				'cookies-accepted': 1,
			}
		}).done(function () {
			$('.section-cookies').addClass('mod-hide').removeClass('show');
		});
	});

	document.querySelector('.section-cookies .btn-decline').addEventListener('click', (e) => {
		e.preventDefault();

		$.ajax({
			method: "POST",
			url: "api/cookies/",
			data: {
				'cookies-accepted': 0,
			}
		}).done(function () {
			$('.section-cookies').addClass('mod-hide').removeClass('show');
		});
	});

	// Ajax referral form processing
	$('form.main-form').submit(function (e) {
		e.preventDefault();

		// if honeypot field is filled in by spambot, do not submit
		if ($('input#Form_Email').val().length != 0) {
			return false;
		}

		$.ajax({
			method: 'POST',
			url: 'process/contact/',
			data: $(this).serialize()
		}).done(function (response) {

			// If no errors
			if (response == 'success') {
				// Reset form completely
				$('form.main-form .form-element').removeClass('mod-filled');
				$('form.main-form').hide().trigger('reset');

				// Show thank you message
				$('form.main-form + .wrap-thanks').fadeIn();

				// Remove any errors that may still be there
				$('form.main-form .sub-errors').html('');

				// Hide thank you message after 10 seconds and return to form
				setTimeout(function () {
					$('form.main-form').fadeIn();
					$('form.main-form + .wrap-thanks').hide();
				}, 10000);

			}
			else {
				$('form.main-form .sub-errors').fadeIn().html(response);
			}
		});
	});

	if ($('form').length) {
		$('form input, form textarea').focus(function () {
			$(this).parent().addClass('mod-filled');

		}).blur(function () {
			if ($(this).val()) {
				$(this).parent().addClass('mod-filled');
			} else {
				$(this).parent().removeClass('mod-filled');
			}
		});
	}


	// Add class to inputs when clicked on add effect
	const form = document.querySelectorAll('form');

	if (form) {
		document.querySelectorAll('form input, form textarea').forEach((element) => {
			element.addEventListener('focus', () => {
				element.parentElement.classList.add('mod-filled');
			});

			element.addEventListener('blur', () => {
				if (element.value) {
					element.parentElement.classList.add('mod-filled');
				} else {
					element.parentElement.classList.remove('mod-filled');
				}
			});
		});
	}

	$(window).on('load resize', function () {
		if ($('.sub-pagination .list-pages').length && $('#news').length) {
			const bubble = $("ul.list-pages .track-bubble"),
				bubbleActive = $("ul.list-pages li.active");
	
			function resetBubble() {
				bubble.css('left', bubbleActive.position().left);
				bubbleActive.find('a').css('color', '#fff');
			}
	
			resetBubble();
	
			$('ul.list-pages li:not(.mod-arrow)').hover(function () {
				bubble.css('left', $(this).position().left);
				bubbleActive.find('a').css('color', '#78828C');
			}, function () {
				resetBubble();
			});
		}
	});

	// Ensure the function exists on the page before we call it.
	if (!!window['gdprSubscribeModal']) {
		// The form of which to attach the modal to.
		const formSelector = '#contact-form';
		// The url to link to for the privacy policy.
		const privacyPolicyURL = 'privacy-policy.html';
		// Color of the links in the modal
		const linkColour = '#000';
		// Color of the buttons in the modal.
		const buttonTextColour = '#000';

		// Call this per form.
		gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
	}

	// Pops out share modal window
	document.querySelectorAll('.social-share').forEach((element) => {
		element.addEventListener('click', (e) => {

			// Prevents default action
			e.preventDefault();
			// Runs window popup function
			windowPopup($(this).attr("href"), 500, 300);
		});
	});

	$('a.js-jump').on('click', function (ev) {
		ev.preventDefault();
	
		var link = $(this).attr('href').split('#').pop();
		var targetScroll = $('#' + link).offset().top;
	
		$('html, body').animate({ scrollTop: targetScroll }, 700, 'swing');
	});


	// Mobile Nav
	$('.mob-hamburger').click(function (e) {
		e.preventDefault();

		$(this).toggleClass('mod-active');
		$('body').toggleClass('no-scroll');
		$('#nav > ul').toggleClass('mod-active');
		$('.logo').fadeToggle();
	});

	// Function for popout window
	function windowPopup(url, width, height) {
		// Calculate the position for the popup so it’s centered on the screen.
		const left = (screen.width / 2) - (width / 2);
		const top = (screen.height / 2) - (height / 2);

		// opens a window with the given url & set position from above
		window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
	}

	/** Slick.Js Stuff
	 *
	 */

	// Custom dots for slick
	const svg = '<svg xmlns = "http://www.w3.org/2000/svg" viewBox = "0 0 16 16">' +
	'<path class="circle-bg" d="M8,14.5A6.5,6.5,0,1,1,14.5,8,6.5,6.5,0,0,1,8,14.5Z"></path>' +
	'<path class="circle-fill" d="M8,14.5A6.5,6.5,0,1,1,14.5,8,6.5,6.5,0,0,1,8,14.5Z"></path>' +
	'</svg >';

	if ($('#news').length) {
		// Featured News - Articles
		$('ul.list-featured').slick({
			arrows: false,
			autoplay: true,
			autoplaySpeed: 5000,
			appendDots: $('.sub-testimonials-nav'),
			dots: true,
			asNavFor: '.sub-images',
			accessibility: true,
			swipeToSlide: true,
			customPaging: function (slider, i) {
				return svg;
			},
		});

		$('.sub-images').slick({
			arrows: false,
			autoplay: true,
			autoplaySpeed: 7000,
			draggable: false,
			dots: false,
			fade: true,
			asNavFor: '.list-featured',
		});
	}

	// Testimonials
	$('ul.list-testimonials').slick({
		arrows: false,
		autoplay: true,
		autoplaySpeed: 7000,
		appendDots: $('.sub-testimonials-nav'),
		accessibility: true,
		swipeToSlide: true,
		dots: true,
		customPaging: function (slider, i) {
			return svg;
		},
	});
});